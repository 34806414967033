import React from 'react';
import { MainMealList } from './MainMealList.js';

export class Main extends React.Component {
    render() {
        return (
            <div>
                {this.props.userdata.namn}&nbsp;<button onClick={this.props.onNewMeal}>Ny måltid</button><br/>
                <MainMealList client={this.props.client} onOpenMeal={this.props.onOpenMeal}/>
            </div>
        );
    }
  }
  