import React from 'react';
import { Main } from './Main.js';
import { Meal } from './Meal.js';
import * as global from './global';

export class Spiff extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            initiated: false,
            navigation: 'main',
        };

        this.handleNewMeal = this.handleNewMeal.bind(this);
        this.handleCloseMeal = this.handleCloseMeal.bind(this);
        this.handleDeleteMeal = this.handleDeleteMeal.bind(this);
        this.handleOpenMeal = this.handleOpenMeal.bind(this);
    }

    componentDidMount() {
        fetch(global.api + "/" + this.props.client + "/getuserdata")
        .then(res => res.json())
        .then(
            (res) => {
                this.setState({
                    userdata: res,
                    initiated: true,
                });
            },
            (error) => {
                alert(error);
            }
        )
    }
  
    handleNewMeal(e)
    {
        this.setState({
            mealId: undefined,
            navigation: 'meal',
        });
    }

    handleCloseMeal(e)
    {
        this.setState({
            navigation: 'main',
        });
    }

    handleDeleteMeal(mealId)
    {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
            this.setState({
                navigation: 'main',
            });
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealdelete/" + mealId);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send();
    }

    handleOpenMeal(mealId)
    {
        this.setState({
            mealId: mealId,
            navigation: 'meal'
        });
    }

    render() {
        if(!this.state.initiated)
        {
            return (<div>Laddar...</div>)
        }
        else 
        {
            if(this.state.navigation === 'main')
            {
                return <Main client={this.props.client} userdata={this.state.userdata} onNewMeal={this.handleNewMeal} onOpenMeal={this.handleOpenMeal}/>;
            }
            else
            if(this.state.navigation === 'meal')
            {
                return <Meal client={this.props.client} userdata={this.state.userdata} onCloseMeal={this.handleCloseMeal} onDeleteMeal={this.handleDeleteMeal} mealId={this.state.mealId} />;
            }
            else
            return (
            <div>
                {this.state.userdata.namn} Navigation broken   
            </div>);
        }
    }
  }
  