import React from 'react';
import * as global from './global';

export class MainMealList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            initiated: false,
        };
        this.onOpenMeal = this.onOpenMeal.bind(this);
    }

    componentDidMount() {
        fetch(global.api + "/" + this.props.client + "/meallist")
        .then(res => res.json())
        .then(
            (res) => {
                this.setState({
                    meal: res,
                    initiated: true,
                });
            },
            (error) => {
                alert(error);
            }
        )
    }

    onOpenMeal(e)
    {
        this.props.onOpenMeal(e.target.value)
    }

    render() {
        if(!this.state.initiated)
        {
            return (<div>Laddar...</div>)
        }
        else if(this.state.meal.length > 0)
        {
            return (
                <table>
                    <thead>
                        <tr><th>Datum</th><th>Namn</th></tr>
                    </thead>
                    <tbody>
                    {this.state.meal.map((item, index) => (
                        <tr key={item.mealId}>
                            <td>{item.createdDate.substring(0, 16)}</td>
                            <td>{item.mealName}</td>
                            <td><button value={item.mealId} onClick={this.onOpenMeal}>Öppna</button></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            );
        }
        else
        {
            return <>Inga tidigare måltider hittades</>
        }
    }
  }
  