import React from 'react';
import { MealItem } from './MealItem.js';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Autocomplete from "./Autocomplete";
import * as global from './global';

export class Meal extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = { 
            initiated: false,
            };
        this.updateMeal = this.updateMeal.bind(this);
        this.handleMealItemAdd = this.handleMealItemAdd.bind(this);
        this.handleUpdateMealItem = this.handleUpdateMealItem.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onDeleteMeal = this.onDeleteMeal.bind(this);
        this.handleMealItemDelete = this.handleMealItemDelete.bind(this);
        this.handleMealItemDuplicate = this.handleMealItemDuplicate.bind(this);
        this.handleMealItemTemplateDelete = this.handleMealItemTemplateDelete.bind(this);
        this.handleMealItemTemplateAdd = this.handleMealItemTemplateAdd.bind(this);
        this.handleMealItemTemplateCreateUpdate = this.handleMealItemTemplateCreateUpdate.bind(this);
        this.handleGKHClick = this.handleGKHClick.bind(this);
    }

    handleMealItemDelete(mealItemId)
    {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
            fetch(global.api + "/" + this.props.client + "/mealget/" + this.state.meal.mealId)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        meal: res,
                    });
                    this.calc();
                },
                (error) => {
                    alert(error);
                }
            ) 
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealitemdelete/" + mealItemId);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send();
    }

    handleMealItemDuplicate(mealItemId)
    {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
            fetch(global.api + "/" + this.props.client + "/mealget/" + this.state.meal.mealId)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        meal: res,
                    });
                    this.calc();
                },
                (error) => {
                    alert(error);
                }
            ) 
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealitemclone/" + mealItemId);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send();
    }

    handleMealItemTemplateCreateUpdate(mealItemTemplateName, carbsPerHg, g)
    {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
            fetch(global.api + "/" + this.props.client + "/mealget/" + this.state.meal.mealId)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        meal: res,
                    });
                    this.calc();
                },
                (error) => {
                    alert(error);
                }
            ) 
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealitemtemplatecreateupdate/" + mealItemTemplateName + "/" + carbsPerHg + "/" + (g ? g : ""));
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send();
    }

    handleMealItemTemplateDelete(mealItemTemplateName)
    {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
            fetch(global.api + "/" + this.props.client + "/mealget/" + this.state.meal.mealId)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        meal: res,
                    });
                    this.calc();
                },
                (error) => {
                    alert(error);
                }
            ) 
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealitemtemplatedelete/" + mealItemTemplateName);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send();
    }

    onDeleteMeal()
    {
        if(window.confirm("Är du säker på att denna måltid ska raderas?"))
        {
            this.props.onDeleteMeal(this.state.meal.mealId);
        }
    }

    toNumber(s)
    {
        return Number(s.toString().replace(',','.'));
    }

    numberFormat(n, emptyZero)
    {
        var value = Number.isNaN(n) || n === undefined ? '' : n.toFixed(2).toString().replace('.',',');
        return emptyZero && value === '0,00' ? '' : value;
    }

    onBlur(e)
    {
        e.target.value = this.numberFormat(this.state.meal[e.target.name]);
    }

    handleGKHClick(e)
    {
        var copyText = document.getElementById('gkh');
        copyText.hidden = false;
        copyText.select();
        copyText.setSelectionRange(0, 99999); 
      
        document.execCommand("copy");
        copyText.hidden = true;

        alert(copyText.value + ' är kopierat');
    }

    calc()
    {
        const meal = this.state.meal;
        var arrayLength = meal.mealItems.length;
        var gkh = 0;

        for (var i = 0; i < arrayLength; i++) {
            var mealItem = meal.mealItems[i];
            mealItem.gkh = mealItem.g * mealItem.carbsPerHg / 100;
            gkh+=mealItem.gkh;
        }
        var suggestedBolus = gkh / meal.carbRatio;
        var diff = (suggestedBolus - meal.bolus) * meal.isf;
        var predictedBg = meal.bg + diff;

        this.setState({
            meal: meal,
            gkh: gkh,
            suggestedBolus: Number.isNaN(suggestedBolus) ? undefined : suggestedBolus,
            diff: Number.isNaN(diff) ? undefined : diff,
            predictedBg : Number.isNaN(predictedBg) ? undefined : predictedBg,
        });
    }

    updateMeal(e)
    {
        const meal = this.state.meal;
        if(e.target.inputMode==='decimal'){
            meal[e.target.name] = this.toNumber(e.target.value);
        }
        else{
            meal[e.target.name] = e.target.value;
        }
        this.setState({meal: meal});

        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
           //console.log(xhr.responseText)
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealupdate");
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(JSON.stringify(this.state.meal));

        this.calc();
    }

    handleUpdateMealItem(mealItem)
    {
        const meal = this.state.meal;
        var itemIndex = meal.mealItems.findIndex(function(c) { 
            return c.mealItemId === mealItem.mealItemId; 
        });
    
        meal.mealItems[itemIndex] = mealItem;
        this.setState({
            meal: meal,
        });

        var xhr = new XMLHttpRequest()
        xhr.addEventListener("load", () => {
           //console.log(xhr.responseText)
        })
        xhr.open("POST", global.api + "/" + this.props.client + "/mealitemupdate/" + this.state.meal.mealId);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(JSON.stringify(mealItem))

        this.calc();
    }

    componentDidMount() {
        if(this.props.mealId === undefined)
        {
            fetch(global.api + "/" + this.props.client + "/mealcreate")
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        meal: res,
                        initiated: true,
                    });
                    this.calc();
                },
                (error) => {
                    alert(error);
                }
            )
        }else{
            fetch(global.api + "/" + this.props.client + "/mealget/" + this.props.mealId)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        meal: res,
                        initiated: true,
                    });
                    this.calc();
                },
                (error) => {
                    alert(error);
                }
            ) 
        }
    }

    handleMealItemAdd()
    {
        fetch(global.api + "/" + this.props.client + "/mealitemcreate/" + this.state.meal.mealId)
        .then(res => res.json())
        .then(
            (res) => {
                this.setState({
                    meal: res,
                });
                this.calc();
            },
            (error) => {
                alert(error);
            }
        )  
    }
 
    handleMealItemTemplateAdd(e)
    {
        fetch(global.api + "/" + this.props.client + "/mealitemfromtemplatecreate/" + this.state.meal.mealId + "/" + e.target.value)
        .then(res => res.json())
        .then(
            (res) => {
                res.mealItems[res.mealItems.length - 1].g = this.toNumber(window.prompt("Ange vikt " + res.mealItems[res.mealItems.length - 1].mealItemName, this.numberFormat(res.mealItems[res.mealItems.length - 1].g, true)));
                this.handleUpdateMealItem(res.mealItems[res.mealItems.length - 1]);
                this.setState({
                    meal: res,
                });
                this.calc();
            },
            (error) => {
                alert(error);
            }
        ) 
        this.setState({
            templateId: "",
        });
    }

    render() {
        if(!this.state.initiated)
        {
            return (<div>Laddar...</div>)
        }
        else 
        {
            const meal = this.state.meal;
            return (
            <div>
                {this.props.userdata.namn}&nbsp;{meal.bgString}&nbsp;Måltid skapad:{meal.createdDate}<br/>
                Måltid:&nbsp;<input type="text" onChange={this.updateMeal} value={meal.mealName} name="mealName" /><br/>
                <hr/>
                <button onClick={this.handleMealItemAdd}>Skapa tom rad</button><br/>
                {/*
                <select onChange={this.handleMealItemTemplateAdd} value={this.state.templateId}>
                    <option value="">Skapa rad från mall</option>
                    {this.props.userdata.mealItemTemplate.map((item, index) => (
                        <option key={item.mealItemTemplateId} value={item.mealItemTemplateId}>{item.mealItemTemplateName}</option>
                    ))}
                </select>*/}
                Sök för att skapa rad från mall:
                <Autocomplete
                options={this.props.userdata.mealItemTemplate}
                onSelect={this.handleMealItemTemplateAdd}
                />
                <hr/>

                {meal.mealItems.length ? (
                <Table style = {{width: 340}}>
                    <Thead>
                        <Tr><Th>Mat</Th><Th>Vikt (g)</Th><Th>KH per 100g</Th><Th>gKH</Th><Th></Th></Tr>
                    </Thead>
                    <Tbody>
                    {meal.mealItems.map((item, index) => (
                        <MealItem mealItem={item} key={item.mealItemId} handleMealItemDelete={this.handleMealItemDelete} handleMealItemTemplateDelete={this.handleMealItemTemplateDelete} handleMealItemDuplicate={this.handleMealItemDuplicate} handleMealItemTemplateCreateUpdate={this.handleMealItemTemplateCreateUpdate} onUpdateMealItem={this.handleUpdateMealItem} numberFormat={this.numberFormat} toNumber={this.toNumber}/>
                    ))}
                    </Tbody>
                </Table>
                ):""
                }
                <input type='text' id='gkh' value={this.numberFormat(this.state.gkh)} readOnly hidden/>
                <table>
                    <tbody>
                        <tr>
                            <td>Summa gKH:</td>
                            <td style={{fontWeight: 'bold'}} onClick={this.handleGKHClick}>{this.numberFormat(this.state.gkh)}</td>
                            <td>BG:</td>
                            <td><input inputMode="decimal" onChange={this.updateMeal} onBlur={this.onBlur} defaultValue={this.numberFormat(meal.bg)} name="bg" style = {{width: 50}}/></td>
                        </tr>
                        <tr>
                            <td>Kolhydratskvot:</td>
                            <td><input inputMode="decimal" onChange={this.updateMeal} onBlur={this.onBlur} defaultValue={this.numberFormat(meal.carbRatio)} name="carbRatio" style = {{width: 50}}/></td>
                            <td>Bolus:</td>
                            <td><input inputMode="decimal" onChange={this.updateMeal} onBlur={this.onBlur} defaultValue={this.numberFormat(meal.bolus, true)} name="bolus" style = {{width: 50}}/></td>
                        </tr>
                        <tr>
                            <td>Insulinkänslighet:</td>
                            <td><input inputMode="decimal" onChange={this.updateMeal} onBlur={this.onBlur} defaultValue={this.numberFormat(meal.isf)} name="isf" style = {{width: 50}}/></td>
                            <td>Diff:</td>
                            <td>{this.numberFormat(this.state.diff)}</td>
                        </tr>
                        <tr>
                            <td>Föreslagen bolus:</td>
                            <td>{this.numberFormat(this.state.suggestedBolus)}</td>
                            <td>Förutspådd bg:</td>
                            <td>{this.numberFormat(this.state.predictedBg)}</td>
                        </tr>
                    </tbody>
                </table>

                <hr/><button onClick={this.props.onCloseMeal}>Stäng</button>&nbsp;<button onClick={this.onDeleteMeal}>Radera</button><br/>
            </div>);
        }
    }
  }
  /*
{
  "mealId": 1,
  "mealName": "",
  "createdDate": "2020-07-19 20:46:27",
  "bg": 5.80,
  "bgString": "5.8 ? +0.2",
  "carbRatio": 0,
  "isf": 0,
  "bolus": 0.00,
  "mealItems": [
    {
      "mealItemId": 1,
      "mealItemName": null,
      "g": 0.0,
      "carbsPerHg": 0.0
    },
    {
      "mealItemId": 2,
      "mealItemName": null,
      "g": 0.0,
      "carbsPerHg": 0.0
    }
  ]
}
*/