import React from 'react';
import { Tr, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

export class MealItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            initiated: false,
        };
        this.updateMealItem = this.updateMealItem.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleAction = this.handleAction.bind(this);

    }

    componentDidMount() {
        this.setState({
            initiated: true,
        });
    }

    updateMealItem(e)
    {
        const mealItem = this.props.mealItem;
        if(e.target.inputMode==='decimal'){
            mealItem[e.target.name] = this.props.toNumber(e.target.value);
        }
        else{
            mealItem[e.target.name] = e.target.value;
        }
        this.props.onUpdateMealItem(mealItem);
    }
  
    handleAction(e)
    {
        if(e.target.value === "1" && window.confirm("Radera rad?"))
        {
            this.props.handleMealItemDelete(this.props.mealItem.mealItemId);
        } else
        if(e.target.value === "2" && window.confirm("Duplicera rad?"))
        {
            this.props.handleMealItemDuplicate(this.props.mealItem.mealItemId);
        } else
        if(e.target.value === "3" && window.confirm("Lägg/uppdatera favorit?"))
        {
            const mealItem = this.props.mealItem;
            this.props.handleMealItemTemplateCreateUpdate(mealItem.mealItemName, mealItem.carbsPerHg);
        } else
        if(e.target.value === "4" && window.confirm("Lägg/uppdatera favorit med vikt?"))
        {
            const mealItem = this.props.mealItem;
            this.props.handleMealItemTemplateCreateUpdate(mealItem.mealItemName, mealItem.carbsPerHg, mealItem.g);
        } else
        if(e.target.value === "5" && window.confirm("Radera favorit?"))
        {
            const mealItem = this.props.mealItem;
            this.props.handleMealItemTemplateDelete(mealItem.mealItemName);
        }
        this.setState({
            actionId: "",
        });
    }

    onBlur(e)
    {
        e.target.value = this.props.numberFormat(this.props.mealItem[e.target.name]);
    }

    render() {
        const mealItem = this.props.mealItem;
        return (
            <Tr>
                <Td><input type="text" onChange={this.updateMealItem} value={mealItem.mealItemName} name="mealItemName" /></Td>
                <Td><input inputMode="decimal" onChange={this.updateMealItem} onBlur={this.onBlur} defaultValue={this.props.numberFormat(mealItem.g, true)} name="g" style = {{width: 100}}/></Td>
                <Td><input inputMode="decimal" onChange={this.updateMealItem} onBlur={this.onBlur} defaultValue={this.props.numberFormat(mealItem.carbsPerHg, true)} name="carbsPerHg" style = {{width: 100}}/></Td>
                <Td>{this.props.numberFormat(mealItem.gkh)}</Td>
                <Td>
                    <select onChange={this.handleAction} value={this.state.actionId}>
                        <option value="">Åtgärd</option>
                        <option key="1" value="1">Radera rad</option>
                        <option key="2" value="2">Duplicera rad</option>
                        <option key="3" value="3">Lägg/uppdatera favorit</option>
                        <option key="4" value="4">Lägg/uppdatera favorit med vikt</option>
                        <option key="5" value="5">Radera favorit</option>
                    </select>
                </Td>
            </Tr>
        );
    }
  }
  /*
  "mealItems": [
    {
      "mealItemId": 1,
      "mealItemName": null,
      "g": 0.0,
      "carbsPerHg": 0.0
    }
  ]
  */