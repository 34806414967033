import React from 'react';
import { Spiff } from './Spiff.js';

function App() {
    return (
    <Spiff client={(new URLSearchParams(window.location.search)).get("client")} />
  );
}

export default App;
